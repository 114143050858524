import { render, staticRenderFns } from "./unread-message-count.vue?vue&type=template&id=e3813100&scoped=true&"
import script from "./unread-message-count.vue?vue&type=script&lang=js&"
export * from "./unread-message-count.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3813100",
  null
  
)

export default component.exports