<script>
import Repository from "../../app/repository/repository-factory";

const SubaccountRepository = Repository.get("SubaccountRepository");
export default {
  components: {},
  data() {
    return {
      preloader: true,
      selected: null,
      selectedUuid: null,
      options: []
    }
  },
  created() {
    this.getQuickSearchSubaccount();
  },
  methods: {
    getQuickSearchSubaccount() {
      SubaccountRepository.getQuickSearch().then((response) => {
        this.options = response.data;
      }).catch(error => {
        console.log(error);
      })
    },
    redirectToSubaccount() {
      let i;
      for (i = 0; i < this.options.length; i++) {
        if (this.selected.normalize() === this.options[i].fullName.normalize()) {
          this.$router.push({name: "Profil podopiecznego", params: {id: this.options[i].uuid}});
          this.selected = '';
        }
      }
    }
  }
};
</script>

<template>
  <form class="app-search d-block">
    <div class="position-relative">
      <b-form-input
        autocomplete="off"
        v-on:change="redirectToSubaccount"
        v-on:select="redirectToSubaccount"
        list="input-list"
        v-model="selected"
        id="input-with-list"
        placeholder="Szukaj podopiecznego..."
      ></b-form-input>

      <datalist id="input-list">
        <option v-for="option in options" :value="option.fullName" v-bind:key="option.uuid">{{ option.fullName }}</option>
      </datalist>
      <span class="bx bx-search-alt"></span>
    </div>

  </form>
</template>
