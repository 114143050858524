<script>
import {AuthJwtService} from '@/app/service/auth/auth-jwt-service';
import UnreadMessageCount from "@/components/message/unread-message-count";

export default {
  components: {UnreadMessageCount},
  data() {
    return {
      messageModuleEnabled: process.env.VUE_APP_MODULE_MESSAGE_ENABLED
    }
  },
  methods: {
    isAdmin() {
      return AuthJwtService.isGranted('ROLE_ADMIN');
    },
    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    }
  }
};
</script>
<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div id="topnav-menu-content" class="collapse navbar-collapse">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link tag="a" class="nav-link" to="/">
                <i class="bx bx-home-circle mr-2"></i>Dashboard
              </router-link>
            </li>

            <li class="nav-item">
              <router-link tag="a" class="nav-link" to="/account/list">
                <i class="bx bx-user mr-2"></i>Pracownicy
              </router-link>
            </li>


            <li class="nav-item dropdown">
              <a
                id="topnav-components2"
                class="nav-link dropdown-toggle arrow-none"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                @click="onMenuClick"
              >
                <i class="bx bx-face mr-2"></i>Podopieczni
                <div class="arrow-down"></div>
              </a>
              <div
                class="dropdown-menu mega-dropdown-menu px-2"
                aria-labelledby="topnav-components"
              >
                <div class="row">
                  <div class="col-lg-4">
                    <router-link tag="a" to="/subaccount/add" class="dropdown-item">Dodaj podopiecznego</router-link>
                    <router-link tag="a" to="/subaccount/list" class="dropdown-item">Lista podopiecznych</router-link>
                  </div>

                </div>
              </div>
            </li>

            <li v-if="messageModuleEnabled === 'true'" class="nav-item">
              <router-link tag="a" class="nav-link" to="/message/list/inbox">
                <span><i class="bx bx-message-dots mr-2"></i>Wiadomości
                       <unread-message-count/>
                </span>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link tag="a" class="nav-link" to="/import/import">
                <i class="bx bx-import mr-2"></i>Import z pliku
              </router-link>
            </li>

            <li class="nav-item">
              <router-link tag="a" class="nav-link" to="/application/report">
                <i class="bx bxs-file-doc mr-2"></i>Wnioski
              </router-link>
            </li>

            <li class="nav-item">
              <router-link tag="a" class="nav-link" to="/report/alloperations">
                <i class="bx bxs-report mr-2"></i>Raport operacji
              </router-link>
            </li>

          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
