<script>
import {mapState} from "vuex";
import simplebar from "simplebar-vue";
import SubaccountSearchTopbar from "@/components/subaccount/subaccount-search-topbar";

export default {
  components: {
    simplebar, SubaccountSearchTopbar
  },
  data() {
    return {
      logoPath: require(process.env.VUE_APP_LOGO_MAIN_PATH)
    }
  },
  computed: {
    ...mapState({
      authState: state => state.auth
    }),
  },
  methods: {
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    }
  }
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img :src="logoPath" alt height="22" />
            </span>
            <span class="logo-lg">
              <img :src="logoPath" alt height="40" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img :src="logoPath" alt height="22" />
            </span>
            <span class="logo-lg">
              <img :src="logoPath" alt height="40" />
            </span>
          </router-link>
        </div>

        <button
          id="toggle"
          type="button"
          class="btn btn-sm mr-2 font-size-16 d-lg-none header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

       <!-- App Search-->
        <SubaccountSearchTopbar/>
      </div>

      <div class="d-flex">
        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown v-if="1 === 0"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0"
          variant="black"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell bx-tada"></i>
            <span class="badge badge-danger badge-pill">3</span>
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">Ostatnie powiadomienia</h6>
              </div>
              <div class="col-auto">
                <a href="#!" class="small">Zobacz wszystkie</a>
              </div>
            </div>
          </div>
          <simplebar data-simplebar style="max-height: 230px;">
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-plus-circle"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Dodano wpłatę</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">Dodano wpłatę dla Mateusz Kowalski na kwotę 500.00 PLN</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> 3 min ago
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-log-in-circle"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Logowanie do systemu</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">Paulina Kowalska zalogowała się do systemu.</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> 1 hours ago
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-user-circle"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">Edycja podopiecznego</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">Maria Nowak zmieniła dane Łukasz Kowalskiego.</p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i> 3 min ago
                    </p>
                  </div>
                </div>
              </div>
            </a>

          </simplebar>
          <div class="p-2 border-top">
            <a class="btn btn-sm btn-light btn-block text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-down-circle mr-1"></i> Wczytaj więcej..
            </a>
          </div>
        </b-dropdown>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>

            <img v-if="authState.user.profileImageUrl.length > 0"
              class="rounded-circle header-profile-user"
              v-bind:src="authState.user.profileImageUrl"
              alt="Header Avatar"
            />
            <img v-if="authState.user.profileImageUrl.length == 0"
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />

            <span class="d-none d-xl-inline-block ml-1">{{ authState.user.firstName }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <b-link :to="{ name: 'Profil pracownika', params: { id: authState.user.uuid } }">
            <div class="dropdown-item">
              <i class="bx bx-user font-size-16 align-middle mr-1"></i> Twój profil
            </div>
          </b-link>

          <b-link :to="{ name: 'Profil pracownika', params: { id: authState.user.uuid } }">
            <div class="dropdown-item d-block">
                <i class="bx bx-wrench font-size-16 align-middle mr-1"></i> Zmień hasło
            </div>
          </b-link>

          <div class="dropdown-divider"></div>
          <b-link :to="{ name: 'Wyloguj' }">
            <div class="dropdown-item text-danger">
              <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Wyloguj
            </div>
          </b-link>
        </b-dropdown>


      </div>
    </div>
  </header>
</template>
